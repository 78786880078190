.c-advantages {
    display         : flex;
    align-content   : center;
    justify-content : space-around;
    flex-wrap       : wrap;

    @media only screen and (min-width : 1025px) {
        flex-wrap : nowrap;
    }

    .c-advantages__item {
        display         : flex;
        align-items     : center;
        flex-direction  : column;
        justify-content : flex-start;
        margin-bottom   : 36px;
        width           : 50%;
        @media only screen and (min-width : 1025px) {
            width : 15%;
        }
    }

    .c-advantages__icon {
        margin-bottom : 18px;
        font-size     : 70px;
        color         : $gray;

        @media only screen and (min-width : 1025px) {
            font-size     : 100px;
            margin-bottom : 30px;
        }
    }

    .c-advantages__title {
        color          : $gray;
        font-family    : $font-base;
        font-size      : 18px;
        font-weight    : bold;
        letter-spacing : 0;
        line-height    : 24px;
        text-align     : center;
        margin-bottom  : 8px;
        padding        : 0 35px;

        @media only screen and (min-width : 1025px) {
            font-size     : 24px;
            line-height   : 32px;
            margin-bottom : 16px;
            padding       : 0 20px;
        }
    }

    .c-advantages__subtitle {
        color          : $gray;
        font-family    : $font-base;
        font-size      : 14px;
        letter-spacing : 0;
        line-height    : 24px;
        text-align     : center;
        padding        : 0 20px;

        @media only screen and (min-width : 1025px) {
            font-size : 20px;
        }
    }
}
